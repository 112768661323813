import { createImprovePlan } from '@api/actionPlan';
import DefaultButton from '@components/Atoms/DefaultButton';
import RoundedSquareButton from '@components/Molecules/RoundedSquareButton';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { Grid } from '@mui/material';
import { ImprovePlanTypes, ShortStatContentTypes } from 'custom.d';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { RootState } from 'store';
import { shortStarWizardActions } from 'store/Slices/ShortStarWizardSlice';
import styles from './index.module.scss';

const FocusSection = ({ handleStep }: FocusSectionProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const wizardData = useAppSelector((state: RootState) => state.shortStarWizard);
  const [selectedImprove, setSelectedImprove] = useState<QuestionsScores | null>(null);

  const [clickedButtonId, setClickedButtonId] = useState<number | null>(null);

  useEffect(() => {
    if (!wizardData.isImproveAreaAlreadySelected && wizardData?.actionPlans?.length === 0) {
      findLowestScoreQuestion();
    } else {
      setSelectedImprove(null);
      setClickedButtonId(null);
    }
  }, [wizardData.isImproveAreaAlreadySelected, wizardData?.actionPlans]);

  const findLowestScoreQuestion = () => {
    if (wizardData.questionsScores.length === 0) {
      setSelectedImprove(null);
      setClickedButtonId(null);

      return;
    }

    const sortedQuestions = [...wizardData.questionsScores].sort((a, b) => a.score - b.score);

    const areAllScoresEqual = sortedQuestions.every(
      (question, index, array) => question.score === array[0].score,
    );

    if (areAllScoresEqual) {
      setSelectedImprove(null);
      setClickedButtonId(null);
    } else {
      setSelectedImprove(sortedQuestions[0]);
      setClickedButtonId(sortedQuestions[0]?.questionId);
    }
  };

  const handleButtonClick = (data: QuestionsScores) => {
    if (clickedButtonId === data.questionId) {
      setClickedButtonId(null);
      setSelectedImprove(null);
    } else {
      setClickedButtonId(data.questionId);
      setSelectedImprove(data);
    }
  };

  const handleContinue = async () => {
    if (selectedImprove) {
      await dispatch(shortStarWizardActions.addImprovementArea(selectedImprove));
      await handleStep('IMPROVE_SECTION');

      if (selectedImprove.questionId) {
        await createImprovePlan(
          ImprovePlanTypes.SHORT_STAR,
          selectedImprove.questionId,
          null,
          null,
        );
      }

      if (!wizardData.isImproveAreaAlreadySelected) {
        await dispatch(shortStarWizardActions.updateImrpoveSectionSelected(true));
      }

      history.push(
        `/improve-plans/?improveAreaId=null&improvePlan=null&questionId=${selectedImprove?.questionId}`,
      );
      handleStep('COMPLETE_SHORT_STAR');
    }
  };

  return (
    <div className={styles.focusSelectionSection}>
      <div>
        {selectedImprove ? (
          <div className={styles.headingContainer}>
            <h4 className={styles.letsFocusText}>
              Let’s focus on your <span>{selectedImprove.questionText} </span>{' '}
              {selectedImprove.questionText !== 'Mood' && 'wellbeing'}, or feel free to select
              another area to focus
            </h4>
          </div>
        ) : (
          <div className={styles.headingContainer}>
            <h4 className={styles.letsFocusText}>Select one of the areas to focus</h4>
          </div>
        )}
      </div>

      <Grid container spacing={2} className={styles.focusSelectionGrid}>
        {wizardData?.questionsScores?.map(
          (button: {
            questionId: number;
            colorTheme: string;
            score: number;
            questionText: string;
            icon: string;
          }) => (
            <Grid item xs={6} key={button.questionId}>
              <RoundedSquareButton
                id={button.questionId}
                colorTheme={button.colorTheme}
                selected={clickedButtonId === button.questionId}
                onClick={() => handleButtonClick(button)}
                score={button.score}
                title={button.questionText}
                icon={button.icon}
              />
            </Grid>
          ),
        )}
      </Grid>

      <div className={styles.continueBtnContainer}>
        <DefaultButton
          variant="primary"
          type="button"
          className={styles.continueBtnPrimary}
          onClick={handleContinue}
          disabled={selectedImprove === null}
        >
          Continue
        </DefaultButton>
      </div>
    </div>
  );
};

FocusSection.defaultProps = {};

interface FocusSectionProps {
  handleStep: Dispatch<SetStateAction<ShortStatContentTypes>>;
}

interface QuestionsScores {
  questionId: number;
  questionInfo?: string;
  questionText: string;
  colorTheme: string;
  generalHappinessQuestion?: boolean;
  icon: string;
  questionnaireId?: number;
  score: number;
}

export default FocusSection;
