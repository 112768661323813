import { getExpertUpcomingBookings } from '@api/experts';
import AudioIcon from '@assets/images/icons/audio-only.svg';
import CalendarIcon from '@assets/images/icons/calendar-check-icon.svg';
import ClockIcon from '@assets/images/icons/clock-icon.svg';
import InstantMessagingIcon from '@assets/images/icons/instant-messaging.svg';
import DefaultIcon from '@assets/images/icons/phone-call-dark.svg';
import RotateIcon from '@assets/images/icons/rotate-icon.svg';
import VideoIcon from '@assets/images/icons/video-only.svg';
import ViewAll from '@assets/images/icons/view-all-icon.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { BookingSessionInterface } from '@interfaces/index';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Skeleton } from '@mui/material';
import { formatDateToDateMonthYear, formatTimeToGMT } from '@utils/date.util';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { USER_NOT_FOUND_IMAGE } from 'custom.d';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';

const ExpertSession: React.FC = () => {
  const { isDesktop } = useWindowDimensions();
  const [bookings, setBookings] = useState<BookingSessionInterface[] | []>([]);
  const [filteredBookings, setFilteredBookings] = useState<BookingSessionInterface[] | []>([]);
  const [showMore, setShowMore] = useState(false);
  const [sessionsCount, setSessionsCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getBookings();
  }, []);

  useEffect(() => {
    if (!showMore) {
      setFilteredBookings(bookings.slice(0, 1));
      setSessionsCount(bookings?.length);
    } else {
      setFilteredBookings(bookings);
      setSessionsCount(bookings?.length);
    }
  }, [showMore, bookings]);

  const getBookings = async () => {
    try {
      setLoading(true);
      const res = await getExpertUpcomingBookings();
      setBookings(res);
    } catch {
      setBookings([]);
    } finally {
      setLoading(false);
    }
  };

  const onViewAllSessions = () => {
    setShowMore((prevState) => !prevState);
  };

  const getIconForType = (typeName: string | undefined | null) => {
    switch (typeName?.toLowerCase()) {
      case 'video':
        return VideoIcon;
      case 'audio':
        return AudioIcon;
      case 'instant messaging':
      case 'im':
        return InstantMessagingIcon;
      default:
        return DefaultIcon;
    }
  };

  return (
    <>
      {loading ? (
        <Skeleton variant="rounded" height={120} className={styles.skeleton} />
      ) : (
        <>
          {filteredBookings?.length === 0 ? (
            <></>
          ) : (
            <>
              <div className={styles.sessionContainer}>
                <div className={styles.header}>
                  <h5>Your upcoming expert session{sessionsCount > 1 && 's'}</h5>
                  {sessionsCount > 1 && isDesktop && (
                    <DefaultButton onClick={onViewAllSessions} className={styles.viewAllSessions}>
                      <Img src={ViewAll} alt="View all sessions" className={styles.viewAllIcon} />
                      <span>{showMore ? 'View less' : 'View all sessions'}</span>
                    </DefaultButton>
                  )}
                </div>

                <div className={styles.sessions}>
                  {filteredBookings.map((dt, index) => (
                    <div key={index}>
                      <div className={styles.sessionInfoContainer}>
                        <div className={styles.left}>
                          <Img
                            src={`https://${dt?.expert?.image}` || USER_NOT_FOUND_IMAGE}
                            alt={`${dt?.expert?.firstName} ${dt?.expert?.lastName}`}
                            className={styles.expertImage}
                          />
                          <span>{`${dt?.expert?.firstName} ${dt?.expert?.lastName}`}</span>
                        </div>

                        <div className={styles.middle}>
                          <div className={styles.bookingContainer}>
                            <div className={styles.row}>
                              <div className={styles.cell}>
                                <Img src={CalendarIcon} alt="Calendar" className={styles.icon} />
                                <span>
                                  {formatDateToDateMonthYear(`${dt?.bookingDetails?.date}`)}
                                </span>
                              </div>
                              <div className={styles.cell}>
                                <Img src={RotateIcon} alt="Rotate" className={styles.icon} />
                                <span>
                                  {dt?.bookingDetails?.duration?.durationName || 0} Minutes
                                </span>
                              </div>
                            </div>
                            <div className={styles.row}>
                              <div className={styles.cell}>
                                <Img src={ClockIcon} alt="Clock" className={styles.icon} />
                                <span>{formatTimeToGMT(`${dt?.bookingDetails?.date}`)}</span>
                              </div>
                              <div className={styles.cell}>
                                <Img
                                  src={getIconForType(dt?.bookingDetails?.type?.typeName)}
                                  alt="Session type"
                                  className={styles.icon}
                                />
                                <span>{dt?.bookingDetails?.type?.typeName || ''}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={styles.right}>
                          <AddToCalendarButton
                            name={`[Reminder] Meeting with Expert - ${dt?.expert?.firstName} ${dt?.expert?.lastName}`}
                            options={[
                              'Microsoft365',
                              'Google',
                              'Outlook.com',
                              'MicrosoftTeams',
                              'Yahoo',
                              'iCal',
                              'Apple',
                            ]}
                            description={`You have a scheduled session with ${dt?.expert?.firstName} ${dt?.expert?.lastName}.
                              Session Details:
                                  - Duration: ${dt?.bookingDetails?.duration?.durationName} minutes
                                  - Type: ${dt?.bookingDetails?.type?.typeName}
                                  - Session URL: ${dt?.sessionURL}
                                  [br]
                                  → [url]${dt?.sessionURL}|Join now[/url]
                                  [br]
                              Please join the session using the provided URL at the scheduled time.
                                `}
                            location="Online"
                            startDate={
                              new Date(`${dt?.bookingDetails?.date}`).toISOString().split('T')[0]
                            }
                            endDate={
                              new Date(`${dt?.bookingDetails?.date}`).toISOString().split('T')[0]
                            }
                            startTime={moment
                              .tz(dt?.bookingDetails?.date, 'GMT')
                              .clone()
                              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                              .format('HH:mm')}
                            endTime={moment
                              .tz(dt?.bookingDetails?.date, 'GMT')
                              .clone()
                              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                              .add(
                                Number(dt?.bookingDetails?.duration?.durationName || 25),
                                'minutes',
                              )
                              .format('HH:mm')}
                            timeZone="currentBrowser"
                            organizer="Hapstar|hello@hapstar.app"
                            attendee={
                              `${dt?.user?.firstName} ${dt?.user?.lastName}|${dt?.user?.email}` ||
                              ''
                            }
                            hideBranding
                            buttonStyle="round"
                            hideCheckmark
                            label="Add to calendar"
                            styleLight="
                              --btn-background: transparent; 
                              --btn-background-hover: transparent;
                              --btn-text: #019df2; 
                              --btn-hover-text: #3fb9fc;
                              --font: 'Titillium Web', sans-serif; 
                              --btn-border: none; 
                              --btn-shadow: none; 
                              --btn-hover-shadow: transparent;
                              --base-font-size-l: 14px;
                              --base-font-size-m: 14px;
                              --base-font-size-s: 14px;   
                              --btn-font-weight: 400;
                              --btn-active-shadow: transparent;
                          "
                          />

                          <a href={dt?.sessionURL} target="_blank" rel="noopener noreferrer">
                            <DefaultButton>Join session</DefaultButton>
                          </a>
                        </div>
                      </div>
                      {index < filteredBookings.length - 1 && <hr className={styles.divider} />}
                    </div>
                  ))}
                </div>

                {!isDesktop && sessionsCount > 1 && (
                  <DefaultButton
                    onClick={onViewAllSessions}
                    className={styles.viewAllSessionsOnScroll}
                  >
                    {showMore ? 'View less' : 'View all sessions'}
                    {showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </DefaultButton>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ExpertSession;
