import { getIpAddress } from '@api/auth/login';
import { expertsPageLog, getAllExperts, getExpertsTopics } from '@api/experts';
import DefaultButton from '@components/Atoms/DefaultButton';
import ExpertCard from '@components/Molecules/ExpertCard';
import ExpertSession from '@components/Organisms/Experts/ExpertSession';
import useGoNative from '@hooks/useGoNative';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { ExpertsResultsResponse } from '@interfaces/index';
import MainLayout from '@layouts/MainLayout';
import { Autocomplete, Grid, Skeleton, TextField } from '@mui/material';
import { CustomAutocompletePopper, CustomPopper, FLIP_POPPER_MODS } from '@utils/mui.util';
import { ExpertLogTypes, USER_NOT_FOUND_IMAGE } from 'custom.d';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from './index.module.scss';

const MuiStyles = {
  autocomplete: {
    width: '100%',
    minWidth: '300px',
    marginTop: '-4px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '40px',
      height: '42px',
      border: '3px solid #019df2',
      '& .MuiInputBase-input': {
        color: '#019df2',
        fontWeight: 600,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#019df2',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#019df2',
      },
      '& .MuiAutocomplete-popupIndicator': {
        color: '#019df2',
      },
    },
    '@media (max-width: 600px)': {
      width: '100%',
      minWidth: '200px',
    },
  },
};

const AllExperts: FunctionComponent = () => {
  const { isDesktop, isTablet } = useWindowDimensions();
  const [experts, setExperts] = useState<ExpertsResultsResponse>({ results: [], total: 0 });
  const [expertsLoading, setExpertsLoading] = useState(false);
  const [expertPage, setExpertPage] = useState<number>(0);
  const [expertPerPage] = useState<number>(24);
  const isMobileApp = useGoNative();
  const [starExpertTopicsDataLoading, setExpertTopicsDataLoading] = useState(true);
  const [expertTopics, setExpertTopics] = useState<{ topicId: number; topic: string }[] | []>([
    { topicId: -999, topic: 'All expertise' },
  ]);

  const [selectedExpertTopic, setSelectedExpertTopic] = useState<{
    topicId: number;
    topic: string;
  }>({ topicId: -999, topic: 'All expertise' });

  useEffect(() => {
    getStarQuestions();
    logExperts();
  }, []);

  useEffect(() => {
    getExperts();
  }, [selectedExpertTopic, expertPage]);

  const getStarQuestions = async () => {
    try {
      setExpertTopicsDataLoading(true);
      const response = await getExpertsTopics();
      const updatedResponse = response.map((dt) => ({
        topicId: dt.id,
        topic: dt.topic,
      }));
      updatedResponse.unshift({ topicId: -999, topic: 'All expertise' });
      setExpertTopics(updatedResponse);
    } catch (error) {
      setExpertTopics([]);
    } finally {
      setExpertTopicsDataLoading(false);
    }
  };

  const getExperts = async () => {
    try {
      setExpertsLoading(true);
      const response: ExpertsResultsResponse = await getAllExperts(
        selectedExpertTopic.topicId === -999 ? null : selectedExpertTopic.topicId,
        null,
        expertPage,
        expertPerPage,
      );
      if (expertPage > 0) {
        setExperts((prevExperts) => ({
          results: [...prevExperts.results, ...response.results],
          total: response.total,
        }));
      } else setExperts(response);
    } catch {
      setExperts({ results: [], total: 0 });
    } finally {
      setExpertsLoading(false);
    }
  };

  const logExperts = async () => {
    const ip = await getIpAddress();
    const req = await expertsPageLog(
      ExpertLogTypes.EXPERTS_PAGE_VISITED,
      ip || null,
      navigator.userAgentData?.platform || '',
      isMobileApp,
    );
  };

  const handleNextPage = () => {
    if (experts.results.length < experts.total) {
      setExpertPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (expertPage > 0) {
      setExpertPage((prev) => prev - 1);
    }
  };

  const handleChange = (event: any, newValue: any) => {
    setSelectedExpertTopic(newValue || { id: -999, name: 'All expertise' });
    setExpertTopicsDataLoading(false);
  };

  return (
    <MainLayout title="Experts" isEmployerPage secondaryNavEnabled>
      <div className={styles.mainContainer}>
        <div className={styles.topBox}>
          <div className="container">
            <div className={styles.content}>
              <h1 className={styles.titleText}>Experts</h1>
              <p className={styles.middleText}>
                We understand that taking charge of your wellbeing is a personal journey, and
                finding the right support makes all the difference. We have curated a diverse team
                of experts, each passionate about helping you thrive.
              </p>
              <p className={styles.middleText}>
                Browse profiles of our experts, each showcasing their areas of specialisation and
                approach. Find someone who resonates with your needs and preferences. When
                you&apos;re ready to take the next step, simply book your appointment directly
                through the platform.
              </p>
            </div>
          </div>
          <div className={styles.abstractImg} />
        </div>

        <div className="container">
          <div className={styles.expertSessionContainer}>
            <ExpertSession />
          </div>

          <div className={styles.filterBox}>
            <p>Filter by expertise</p>

            <div>
              <Autocomplete
                isOptionEqualToValue={(option, value) => option.topicId === value.topicId}
                loading={starExpertTopicsDataLoading}
                includeInputInList
                filterSelectedOptions
                autoHighlight
                onOpen={() => {
                  setExpertTopicsDataLoading(true);
                  setTimeout(() => {
                    setExpertTopicsDataLoading(false);
                  }, 1000);
                }}
                disableClearable
                options={expertTopics}
                getOptionLabel={(option) => option.topic}
                renderInput={(params) => (
                  <TextField {...params} label="" sx={MuiStyles.autocomplete} />
                )}
                value={selectedExpertTopic}
                onChange={handleChange}
                sx={MuiStyles.autocomplete}
                className={styles.autoComplete}
                disablePortal
                PopperComponent={isDesktop ? CustomPopper : CustomAutocompletePopper}
                componentsProps={FLIP_POPPER_MODS}
              />
            </div>
          </div>

          {expertsLoading ? (
            <Grid container spacing={4} className={styles.expertGrid}>
              {Array.from({ length: isDesktop ? 12 : isTablet ? 2 : 1 }).map((_, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={400}
                    key={index}
                    className={styles.itemLoading}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container spacing={4} className={styles.expertGrid}>
              {experts.results.map((data, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <ExpertCard
                    id={data.id}
                    image={
                      data?.expertDetail?.profileImage
                        ? data.expertDetail.profileImage
                        : USER_NOT_FOUND_IMAGE
                    }
                    name={`${data.firstName} ${data.lastName}`}
                    description={data?.expertDetail?.headline || ''}
                    experience={`${data?.expertDetail?.experience || 0} years experience`}
                  />
                </Grid>
              ))}
            </Grid>
          )}

          {experts.total === 0 && !expertsLoading && (
            <div className={styles.noExperts}>
              <p>No experts found</p>
            </div>
          )}

          {experts?.results?.length < experts?.total && (
            <div className={styles.pagination}>
              <DefaultButton
                variant="darken"
                disabled={experts.results.length < expertPerPage}
                onClick={handleNextPage}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Load more&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </DefaultButton>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default AllExperts;
