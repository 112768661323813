import { LearningTagInterface } from '@interfaces/index';
import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getAllLearningTags = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-all-learning-tags`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch learning tags: ${response.statusText}`);
  }

  const result: LearningTagInterface[] = await response.json();

  return result;
};

export const getAllLearningTagsWithContent = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-all-learning-tags-with-content`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch learning tags: ${response.statusText}`);
  }

  const result: LearningTagInterface[] = await response.json();

  return result;
};

export const getAllTagsByContent = async (contentId: number) => {
  const formattedReq = {
    contentId,
  };
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-all-learning-tags-by-content`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  if (!response.ok) {
    const error = new Error(`Failed to get tags by content`);
    throw error;
  }

  const result = await response.json();

  return result;
};

export const configLearningTag = async (req: {
  id: number | null;
  tag: string;
  thumbnailImageUrl: string;
  orderId: number;
  displayInCarousel: boolean;
}) => {
  const formattedReq = {
    id: req.id,
    tag: req.tag,
    thumbnailImageUrl: req.thumbnailImageUrl,
    orderId: req.orderId,
    displayInCarousel: req.displayInCarousel,
  };
  const response = await fetch(`${process.env.REACT_APP_ACTION_PLAN_API_URL}/configure-tag`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  if (!response.ok) {
    const error = new Error(`Failed to configure tag`);
    throw error;
  }

  const result = await response.json();

  return result;
};

export const deleteTagById = async (tagId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/delete-learning-tag/${tagId}`,
    {
      method: 'DELETE',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  let result;
  try {
    result = await response.json();
  } catch (error) {
    return {
      success: true,
    };
  }

  if (result.errors && result.errors[0]) {
    return {
      success: false,
      message: result.errors[0].message || 'Error deleting the category',
    };
  }

  return { success: true, message: 'Success' };
};
